import React from 'react'
// import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
// import Banner from '../components/Banner'


class TermsPage extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Terms of Use | Great Harbour"
                    meta={[
                        { name: 'description', content: '' },
                    ]}
                >
                </Helmet>

                <div id="main">

                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Terms of Use</h2>
                            </header>

                            <div className="text-normal">


                            <h4>WEBSITE TERMS OF USE</h4>

                            <p>Welcome to <a href="https://greatharbour.io/">https://greatharbour.io</a> <strong>(Website)</strong>.</p>

                            <p>This Website is owned and operated by Great Harbour Trading Limited, a limited company registered in the British Virgin Islands under company number 1990517 whose registered office is at 1/F Columbus Centre, PO Box 2283, Road Town, Tortola VG 1110, British Virgin Islands.</p>

                            <p>Your use of the Website is subject to these website terms of use <strong>(Terms)</strong>.  Please read them carefully before using the Website.  If you do not agree to these Terms, please do not use the Website.</p>

                            <p>In these Terms <strong>we/our/us</strong> means Great Harbour Trading Limited, and <strong>you/your</strong> means you as a user of the Website.</p>

                            <p>To contact us, please email info@greatharbour.io.</p>

                            <h4>USE OF THE WEBSITE</h4>

                            <p>By using the Website, you signify your agreement to be bound by these Terms. If you do not agree to accept these Terms, you should not use the Website.
                            We reserve the right to change these Terms at any time and you are advised to review the Terms regularly to ensure that you are aware of any changes. Your continued use of the Website after such changes are posted will be deemed agreement on your part to these Terms, as amended.</p>

                            <p>Please read our <strong>Privacy Notice</strong> which can be found at <a href="https://greatharbour.io/">https://greatharbour.io</a>. This explains how we may collect and use information about you via this Website.
                            One of our core activities is the operation of the Temtum cryptocurrency and the associated wallet services for holding, viewing and transferring Temtum.  We operate a separate website for Temtum users, which can be found at <a href="https://temtum.com/">https://temtum.com</a> ("<strong>Temtum Site</strong>"). If you purchase any Temtum cryptocurrency from the Temtum Site, or set-up a wallet for Temtum, the Terms and Conditions of Temtum Sale / Wallet (the “<strong>Temtum Terms</strong>”) at <a href="https://temtum.com/">https://temtum.com</a> shall govern the purchase or wallet set up.  In the event of any conflict or inconsistency between these Terms and the Temtum Terms, the Temtum Terms shall apply. You should ensure you understand the risks before contemplating a purchase of Temtum or setting-up a Temtum wallet.</p>

                            <p>We have not targeted the information on this Website at the general public of any particular country.  The information on this Website is not intended for distribution to, and this Website is not intended to be accessed by, residents in any country where such distribution or use would contravene any local law or regulatory requirement. In particular, this Website is not intended for distribution to residents of any country or territory in which participation in the use of cryptocurrency or a cryptocurrency wallet is prohibited by applicable law, decree, regulation, treaty, or administrative act. It is your responsibility to ascertain the terms of, and comply with, any local law or regulation to which you are subject.</p>

                            <h4>ACCESSING OUR WEBSITE</h4>

                            <p>Access to our Website is permitted on a temporary basis and we reserve the right to suspend, withdraw, discontinue or change all or any part of our Website without notice.  We will not be liable to you if for any reason our Website is unavailable at any time or for any period.</p>

                            <p>You are responsible for ensuring that all persons who access the Website through your internet connection are aware of these Terms, other applicable terms and conditions and that they comply with them.</p>

                            <p>We may update our Website from time to time, and may change the content at any time.  The content on our Website is provided for general information only. It is not intended to amount to advice on which you should rely.  You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our Website. Under no circumstances should the information on this Website be construed as advice, an offer to sell, or a solicitation to buy any security, commodity or any other kind of financial instrument.</p>

                            <p>Although we make reasonable efforts to update the information on our Website, we make no representations, warranties or guarantees, whether express or implied, that the content on our Website is accurate, complete or up-to-date.</p>

                            <p>If we permit you to post or provide any information on or via this Website, you must ensure that such information does not contravene any applicable laws or infringe any person's legal rights. We do not monitor or edit documents or files posted or provided to us by third parties and accordingly we do not accept any responsibility for any damage or loss you may suffer.</p>

                            <p>You agree that you will not:</p>

                            <ul>
                            <li>attempt to decompile, reverse engineer, disassemble or otherwise to derive source code from the Website;</li>
                            <li>tamper with, hinder the operation of or make unauthorised modifications to the Website including attempting to interfere with the access of any user, host or network;</li>
                            <li>use the Website for any activities which breach any laws or regulations or infringe any third party rights;</li>
                            <li>use the personal information of another person in order to access or use the Website;</li>
                            <li>transmit any bug, virus or other disabling feature to or through the Website; and</li>
                            <li>commercially or promotionally distribute, publish or exploit the Website, or its content, without the express prior written consent from authorised personnel of Great Harbour Trading Limited or the otherwise applicable licensor.</li>
                            </ul>

                            <h4>INTELLECTUAL PROPERTY RIGHTS</h4>

                            <p>We are the owner or the licensee of all intellectual property rights in our Website, the material published on it and cryptocurrency sold on the Temtum Site (including all intellectual property rights in the Temtum blockchain).  Those works are protected, including by copyright laws and treaties around the world.  All such rights are reserved.</p>

                            <p>If you wish to reproduce or use information from this Website, please contact us for express consent. You can do this by addressing your request to info@greatharbour.io.</p>

                            <p>If you print off, copy or download any part of our Website in breach of these Terms, your right to use our Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>

                            <h4>LIABILITY</h4>

                            <p>We provide the Website on an "as is" and "as available" basis and to the fullest extent permissible by law we do not guarantee that our Website will meet particular requirements, or be available, accessible, uninterrupted, timely, secure or operate without error, or that it will be free from viruses, worms, Trojans or other harmful elements.  We recommend that you protect your equipment by having appropriate anti-virus software in place.</p>

                            <p>Where our Website contains links to other sites and resources provided by third parties, these links are provided for your information only.  We have no control over the contents of those sites or resources so we assume no responsibility for the content of such sites.  Such links should not be interpreted as endorsement by us of those linked websites.  We will not be liable for any loss or damage that may arise from your use of them.</p>

                            <p>To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our Website or any content on it, whether express or implied.</p>

                            <p>We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with use of, or inability to use, our Website; or use of or reliance on any content displayed on our Website.  In particular, we will not be liable for:</p>

                            <ul>
                            <li>loss of profits, sales, business, or revenue;</li>
                            <li>business interruption;</li>
                            <li>loss of anticipated savings;</li>
                            <li>loss of business opportunity, goodwill or reputation; or</li>
                            <li>any indirect or consequential loss or damage.</li>
                            </ul>

                            <p>Nothing in these Terms is intended to exclude or limit for any liability that cannot be excluded or limited by law.</p>

                            <h4>LINKING TO OUR WEBSITE</h4>

                            <p>We reserve the right in our absolute discretion to prohibit any link from another site to materials or information on this Website without notice. Any link to material or information on this Website must be neither misleading nor deceptive and must fairly indicate this Website as the destination of the link. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>

                            <h4>APPLICABLE LAW</h4>

                            <p>These Terms shall be governed and construed in accordance with English law and you agree to submit to the non-exclusive jurisdiction of the courts of England in relation to any dispute in relation to them.</p>


                          </div>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default TermsPage
